<template>
	<div>
        <h3 class="font-600 mb-1" v-if="title">{{ title }}</h3>
        <slot name="header"></slot>
        <div class="table-responsive">
            <table class="table mb-0">
                <slot name="thead"></slot>
                <tbody>
                    <tr v-for="(data, key) in dataTable.lists.data" :key="key">
                        <td v-for="(column, kcolumn) in dataTable.columns" :key="kcolumn">
                            <span v-if="(column == 'counter')">
                                {{ (dataTable.lists.current_page - 1) * dataTable.lists.per_page + key + 1 }}
                            </span>
                            <span v-else-if="column == 'created_at' || column == 'updated_at' || column == 'last_login_at'">
                                {{ data[column] | moment("HH.mm • DD-MM-YYYY") }}
                            </span>
                            <span v-else-if="column == 'photo'">
                                <img v-if="data[column] != null" :src="data[column]" class="avatar-small border-0 pointer" @click="lihat(data[column])"> 
                            </span> 
                            <span v-else-if="column == 'photos'">
                                <img v-if="data[column] != null" :src="data[column][0]" class="avatar-small border-0 pointer" @click="lihat(data[column][0])"> 
                            </span> 
                            <span v-else-if="column == 'description' || column == 'address'">
                                {{ html_striptag(data[column]).length > 100 ? html_striptag(data[column]).substring(0, 100) + '...' : html_striptag(data[column])}}
                            </span>
                            <span v-else-if="column == 'price' || column == 'price_per_km' || column == 'min_purchase' || column == 'price_final'">
                                {{ data[column] | currency }}
                            </span>
                            <span v-else-if="column == 'product_total'">
                                {{ data[column] | numFormat }}
                            </span>
                            <span v-else-if="column == 'discount_amount'">
                                <div v-if="data['discount_type'] == 'persen'">{{ data['discount_amount'] | numFormat }} %</div>
                                <div v-if="data['discount_type'] == 'rupiah'">{{ data['discount_amount'] | currency }}</div>
                            </span>
                            <span v-else-if="column == 'period'">
                                {{ data['start_date'] | moment('LL') }} - {{ data['end_date'] | moment('LL') }}
                            </span>
                            <span v-else-if="column == 'is_active' && $route.name == 'UserIndex'" 
                                data-toggle="modal" data-target="#modalChangeStatus" class="pointer" @click="detailUser = JSON.parse(JSON.stringify(data))">
                                <div class="badge badge-md badge-primary" v-if="data[column] == true">• Active</div>
                                <div class="badge badge-md badge-danger" v-else>• Deactive</div>
                            </span>
                            <span v-else-if="column == 'is_active'">
                                <div class="badge badge-md badge-primary" v-if="data[column] == true">• Active</div>
                                <div class="badge badge-md badge-danger" v-else>• Deactive</div>
                            </span>
                            <span v-else-if="column == 'payment_method'">
                                <div v-html="payment_method(data[column])"></div>
                            </span>
                            <span v-else-if="column == 'shipping_status'">
                                <div v-html="shipping_status(data[column])"></div>
                            </span>
                            <span v-else v-html="data[column] == null ? '-' : data[column]"></span>
                        </td>
                        <td>
                            <slot name="action" v-bind:data="data"></slot>
                        </td>
                    </tr>
                </tbody>           
            </table>
            <div class="col text-center mt-1">
                <p class="f-14" v-if="dataTable.lists.total == 0 || !dataTable.lists.data">There is no data</p>
            </div>                                                                           
        </div>
        <PaginateList :data="dataTable.lists" v-if="dataTable.lists.total != 0"/>

        <div class="modal fade text-left show" id="modalChangeStatus" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body pb-0">
                        <div class="row">
                            <div class="col">
                                <p class="f-14 font-600 mb-0">{{detailUser.name}}</p>
                                <p class="f-14 text-secondary mb-0">{{detailUser.phone_number}}</p>
                                <p class="f-14 text-secondary mb-0">{{detailUser.email}}</p>
                            </div>
                            <div class="col text-right">
                                <div class="btn-group">
                                    <button type="button" class="btn btn-sm rounded dropdown-toggle" 
                                        :class="detailUser.is_active ? 'btn-outline-primary' : 'btn-outline-danger'"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Ubah Status">
                                        {{ detailUser.is_active ? 'Active' : 'Deactive' }}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item text-danger" v-if="detailUser.is_active" @click="detailUser.is_active = false">Deactive</a>
                                        <a class="dropdown-item text-primary" v-else @click="detailUser.is_active = true">Active</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer p-2 align-self-center">
                        <button type="button" id="closeModal" class="btn text-primary waves-effect waves-light" data-dismiss="modal">Batal</button>
                        <button type="button" @click="changeStatus()" class="btn btn-primary waves-effect waves-light" :disabled="isLoading">
                            <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Simpan</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
    props: [ 'title', 'dataTable' ],
    components: {
        PaginateList
    },
    data() {
        return {
            isLoading: false,
            detailUser: {
                name: ''
            }
        }
    },
    methods: {
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded p-1'
                }
            })
        },
        payment_method(method) {
            let badge = ''
            switch (method) {
                case 'cod':
                    badge = "<div class='badge badge-md badge-success'>Cash</div>"
                    break;
                case 'payment_gateaway':
                    badge = "<div class='badge badge-md badge-primary'>Midtrans</div>"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        shipping_status(status) {
            let badge = ''
            switch (status) {
                case 'waiting':
                    badge = "<div class='badge badge-md badge-warning'>Belum Bayar</div>"
                    break;
                case 'packing':
                    badge = "<div class='badge badge-md badge-outline-info'>Dikemas</div>"
                    break;
                case 'on_process':
                    badge = "<div class='badge badge-md badge-info'>Dikirim</div>"
                    break;
                case 'finish':
                    badge = "<div class='badge badge-md badge-success'>Selesai</div>"
                    break;
                default:
                    break;
            }

            return badge
        },
        html_striptag(str) {
            return str.replace(/(<([^>]+)>)/gi, "")
        },
        changeStatus()
        {
            let _ = this
            _.axios.put('/user/' + _.detailUser.id, _.detailUser)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    window.$('#closeModal').click()
                    _.$root.$emit('refreshPage')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        }
    },
    mounted() {
        this.$moment.locale('id')
    }
}
</script>