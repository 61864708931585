<template>
    <section>
        <Table 
            :dataTable="table.dataTable"
            :title="table.title">
            <template v-slot:header>
                <div class="row">
                    <div class="col">
                        <div class="input-group form-search-rounded mb-1">
                            <span class="input-group-prepend">
                                <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span> 
                            <input type="text" class="form-control bg-white border-left-0 border-right-0 border-top-0 no-shadow" 
                                placeholder="Search" v-model="search">
                        </div>
                    </div>
                    <div class="col-lg-8 col-xs-12 text-right">
                        <div class="btn-responsive">
                            <button type="button" class="btn btn-secondary" @click="download()" :disabled="table.dataTable.lists.data.length == 0">
                                <div v-if="isLoading">Downloading <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else><i class="fa fa-download"></i> Export</div>
                            </button>
                            &nbsp;
                            <router-link to="/users/customer/add">
                                <button type="button" class="btn btn-primary">
                                    <i class="fa fa-plus"></i> Tambah
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>                
            </template>

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/users/customer/detail/' + data.id">
                        <button type="button" class="btn btn-rounded btn-success waves-effect waves-light" title="Detail">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                    <router-link :to="'/users/customer/edit/' + data.id">
                        <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                            <i class="fa fa-edit"></i>
                        </button>
                    </router-link>
                    <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template> 
        </Table>
    </section>
</template>

<script>
import Table from '@/components/Table.vue'

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                // title: 'Customer',
                dataTable: {
                    header: ['No', 'ID', 'Last Login', 'Nama Pengguna', 'Email', 'Alamat', 'No. Telepon', 'Status', 'Action'],
                    columns: ['counter', 'id', 'last_login_at', 'name', 'email', 'address', 'phone_number', 'is_active'],
                    lists: {
                        data: []
                    },
                }
			},
            search: '',
            limit: 10,
            isLoading: false,
            page: 1
		}
	},
    mounted() {
        let _ = this
        _.index()
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
        _.$root.$on("refreshPage", () => {
            _.index(_.page)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
        this.$root.$off("refreshPage");
    },
    methods: {
        index(page) {
            let _ = this
            _.axios.get('/user?role=customer&key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                    _.page = page
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Pembeli?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/user/' + x.id)
                            .then(resp => {
                                _.index()
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                            })
                            .catch(err=>{
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: err.response.data.errors
                                })
                            })                        
                    }
            })
        },
        download(){
            let _ = this
            _.isLoading = true
            _.axios.get('/user/export?role=customer'
                    + '&key=' + _.search 
                ,{ responseType: 'arraybuffer' })
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Customer-'+ _.$moment().format('DDMMYYYYhhmmss') +'.xlsx'
                    link.click()
                    _.isLoading = false
                })
                .catch(() => {
                    _.isLoading = false
                })
        },
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>