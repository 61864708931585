import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

// import Undermaintenance from '../views/errors/503.vue'
// import NotFound from '../views/errors/404.vue'
import Forbidden from '../views/errors/403.vue'

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import EditProfile from '../views/profile/EditProfile.vue'
import ChangePassword from '../views/profile/ChangePassword.vue'

import UserIndex from '../views/user/Index.vue'
import UserCustomerForm from '../views/user/customer/Form.vue'
import UserCustomerDetail from '../views/user/customer/Detail.vue'
import UserAdminForm from '../views/user/admin/Form.vue'

import RoleIndex from '../views/role/Index.vue'
import RoleForm from '../views/role/Form.vue'

import KategoriIndex from '../views/kategori/Index.vue'

import ProductIndex from '../views/product/Index.vue'
import ProductForm from '../views/product/Form.vue'

import PengirimanIndex from '../views/pengiriman/Index.vue'

import PromoIndex from '../views/promo/Index.vue'
import PromoKuponForm from '../views/promo/kupon/Form.vue'
import PromoKuponDetail from '../views/promo/kupon/Detail.vue'
import PromoProdukForm from '../views/promo/produk/Form.vue'
import PromoProdukDetail from '../views/promo/produk/Detail.vue'

import TransaksiIndex from '../views/transaksi/Index.vue'
import TransaksiDetail from '../views/transaksi/Detail.vue'

import TiketIndex from '../views/tiket/Index.vue'

import PushNotificationIndex from '../views/push_notification/Index.vue'
import PushNotificationForm from '../views/push_notification/Form.vue'

import SponsorIndex from '../views/sponsor/Index.vue'
import SponsorBannerForm from '../views/sponsor/banner/Form.vue'

import SettingIndex from '../views/setting/Index.vue'

import RolePushNotificationIndex from '../views/role/push_notification/Index.vue'
import RolePushNotificationForm from '../views/role/push_notification/Form.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
                meta: {
                    pageTitle: 'Dashboard',
                    icon: 'dashboard'
                }
            },
            {
                path: '/profile/edit',
                name: 'EditProfile',
                component: EditProfile,
                meta: {
                    pageTitle: 'Profil',
                    icon: 'person'
                }
            },
            {
                path: '/profile/change_password',
                name: 'ChangePassword',
                component: ChangePassword,
                meta: {
                    pageTitle: 'Ubah Password',
                    icon: 'lock'
                }
            },
            {
                path: '/transactions',
                name: 'TransaksiIndex',
                component: TransaksiIndex,
                meta: {
                    pageTitle: 'Transaksi',
                    icon: 'receipt',
                    redirect: '/transactions'
                }
            },
            {
                path: '/transactions/detail/:id',
                name: 'TransaksiDetail',
                component: TransaksiDetail,
                meta: {
                    pageTitle: 'Transaksi',
                    icon: 'receipt',
                    redirect: '/transactions'
                }
            },
            {
                path: '/promos',
                name: 'PromoIndex',
                component: PromoIndex,
                meta: {
                    pageTitle: 'Promo',
                    icon: 'new_releases',
                    redirect: '/promos'
                }
            },
            {
                path: '/promos/coupon/add',
                name: 'PromoKuponFormAdd',
                component: PromoKuponForm,
                meta: {
                    pageTitle: 'Promo',
                    icon: 'new_releases',
                    redirect: '/promos'
                }
            },
            {
                path: '/promos/coupon/edit/:id',
                name: 'PromoKuponFormEdit',
                component: PromoKuponForm,
                meta: {
                    pageTitle: 'Promo',
                    icon: 'new_releases',
                    redirect: '/promos'
                }
            },
            {
                path: '/promos/coupon/detail/:id',
                name: 'PromoKuponDetail',
                component: PromoKuponDetail,
                meta: {
                    pageTitle: 'Promo',
                    icon: 'new_releases',
                    redirect: '/promos'
                }
            },
            {
                path: '/promos/product/add',
                name: 'PromoProdukFormAdd',
                component: PromoProdukForm,
                meta: {
                    pageTitle: 'Promo',
                    icon: 'new_releases',
                    redirect: '/promos'
                }
            },
            {
                path: '/promos/product/edit/:id',
                name: 'PromoProdukFormEdit',
                component: PromoProdukForm,
                meta: {
                    pageTitle: 'Promo',
                    icon: 'new_releases',
                    redirect: '/promos'
                }
            },
            {
                path: '/promos/product/detail/:id',
                name: 'PromoProdukDetail',
                component: PromoProdukDetail,
                meta: {
                    pageTitle: 'Promo',
                    icon: 'new_releases',
                    redirect: '/promos'
                }
            },
            {
                path: '/sponsors',
                name: 'SponsorIndex',
                component: SponsorIndex,
                meta: {
                    pageTitle: 'Sponsor',
                    icon: 'campaign',
                    redirect: '/sponsors'
                }
            },
            {
                path: '/sponsors/banner/add',
                name: 'SponsorBannerFormAdd',
                component: SponsorBannerForm,
                meta: {
                    pageTitle: 'Sponsor',
                    icon: 'campaign',
                    redirect: '/sponsors'
                }
            },
            {
                path: '/sponsors/banner/edit/:id',
                name: 'SponsorBannerFormEdit',
                component: SponsorBannerForm,
                meta: {
                    pageTitle: 'Sponsor',
                    icon: 'campaign',
                    redirect: '/sponsors'
                }
            },
            {
                path: '/shippings',
                name: 'ShippingIndex',
                component: PengirimanIndex,
                meta: {
                    pageTitle: 'Pengiriman',
                    icon: 'local_shipping',
                    redirect: '/shippings'
                }
            },
            {
                path: '/categories',
                name: 'CategoryIndex',
                component: KategoriIndex,
                meta: {
                    pageTitle: 'Kategori',
                    icon: 'category',
                    redirect: '/categories'
                }
            },
            {
                path: '/products',
                name: 'ProductIndex',
                component: ProductIndex,
                meta: {
                    pageTitle: 'Produk',
                    icon: 'set_meal',
                    redirect: '/products'
                }
            },
            {
                path: '/products/add',
                name: 'ProductFormAdd',
                component: ProductForm,
                meta: {
                    pageTitle: 'Produk',
                    icon: 'set_meal',
                    redirect: '/products'
                }
            },
            {
                path: '/products/edit/:id',
                name: 'ProductFormEdit',
                component: ProductForm,
                meta: {
                    pageTitle: 'Produk',
                    icon: 'set_meal',
                    redirect: '/products'
                }
            },
            {
                path: '/push_notifications',
                name: 'PushNotificationIndex',
                component: PushNotificationIndex,
                meta: {
                    pageTitle: 'Push Notification',
                    icon: 'notifications',
                    redirect: '/push_notifications'
                }
            },
            {
                path: '/push_notifications/add',
                name: 'PushNotificationFormAdd',
                component: PushNotificationForm,
                meta: {
                    pageTitle: 'Push Notification',
                    icon: 'notifications',
                    redirect: '/push_notifications'
                }
            },
            {
                path: '/push_notifications/edit/:id',
                name: 'PushNotificationFormEdit',
                component: PushNotificationForm,
                meta: {
                    pageTitle: 'Push Notification',
                    icon: 'notifications',
                    redirect: '/push_notifications'
                }
            },
            {
                path: '/users',
                name: 'UserIndex',
                component: UserIndex,
                meta: {
                    pageTitle: 'Manajemen User',
                    icon: 'person',
                    redirect: '/users'
                }
            },
            {
                path: '/users/customer/add',
                name: 'UserCustomerFormAdd',
                component: UserCustomerForm,
                meta: {
                    pageTitle: 'Manajemen User',
                    icon: 'person',
                    redirect: '/users'
                }
            },
            {
                path: '/users/customer/edit/:id',
                name: 'UserCustomerFormEdit',
                component: UserCustomerForm,
                meta: {
                    pageTitle: 'Manajemen User',
                    icon: 'person',
                    redirect: '/users'
                }
            },
            {
                path: '/users/customer/detail/:id',
                name: 'UserCustomerDetail',
                component: UserCustomerDetail,
                meta: {
                    pageTitle: 'Manajemen User',
                    icon: 'person',
                    redirect: '/users'
                }
            },
            {
                path: '/users/admin/add',
                name: 'UserAdminFormAdd',
                component: UserAdminForm,
                meta: {
                    pageTitle: 'Manajemen User',
                    icon: 'person',
                    redirect: '/users'
                }
            },
            {
                path: '/users/admin/edit/:id',
                name: 'UserAdminFormEdit',
                component: UserAdminForm,
                meta: {
                    pageTitle: 'Manajemen User',
                    icon: 'person',
                    redirect: '/users'
                }
            },
            {
                path: '/roles',
                name: 'RoleIndex',
                component: RoleIndex,
                meta: {
                    pageTitle: 'Manajemen Role',
                    icon: 'manage_accounts',
                    redirect: '/roles'
                }
            },
            {
                path: '/roles/add',
                name: 'RoleFormAdd',
                component: RoleForm,
                meta: {
                    pageTitle: 'Manajemen Role',
                    icon: 'manage_accounts',
                    redirect: '/roles'
                }
            },
            {
                path: '/roles/edit/:id',
                name: 'RoleFormEdit',
                component: RoleForm,
                meta: {
                    pageTitle: 'Manajemen Role',
                    icon: 'manage_accounts',
                    redirect: '/roles'
                }
            },
            {
                path: '/roles/notifications',
                name: 'RolePushNotificationIndex',
                component: RolePushNotificationIndex,
                meta: {
                    pageTitle: 'Role: Notification',
                    icon: 'notifications',
                    redirect: '/roles/push_notifications'
                }
            },
            {
                path: '/roles/notifications/add',
                name: 'RolePushNotificationFormAdd',
                component: RolePushNotificationForm,
                meta: {
                    pageTitle: 'Role: Notification',
                    icon: 'notifications',
                    redirect: '/roles/push_notifications'
                }
            },
            {
                path: '/roles/notifications/edit/:id',
                name: 'RolePushNotificationFormEdit',
                component: RolePushNotificationForm,
                meta: {
                    pageTitle: 'Role: Notification',
                    icon: 'notifications',
                    redirect: '/roles/push_notifications'
                }
            },
            {
                path: '/tickets',
                name: 'TicketIndex',
                component: TiketIndex,
                meta: {
                    pageTitle: 'Tiket',
                    icon: 'chat'
                }
            },
            {
                path: '/settings',
                name: 'SettingIndex',
                component: SettingIndex,
                meta: {
                    pageTitle: 'Setting',
                    icon: 'settings'
                }
            },            
            {
                path: '/403',
                name: 'Forbidden',
                component: Forbidden
            }
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    isGuest: true
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } 
    else if ( !store.state.token && !localStorage.getItem('token') ) {
        next({name: 'Login'})
    } 
    else if (to.meta.roles){
        let isThere     = to.meta.roles.filter(x => x == localStorage.getItem('role'))
        let isAllowed   = false
        
        if (isThere.length > 0) {
            isAllowed = true;
        }
        
        if (isAllowed){
            next()
        } else{
            next({name: 'Forbidden'})
        }
    } else {
        next()
    }
})

export default router
