<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">Detail Promo</h3>
                <div class="row">
                    <div class="col-6">
                        <div class="row pl-1">
                            <i class="material-icons f-25 mr-1 text-primary">new_releases</i>
                            <div class="col-lg-8 col-xs-12 align-self-center p-0">
                                <p class="f-14 font-500 mb-0">{{model.name}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-sm btn-outline-primary mr-1" @click="$router.push('/promos/product/edit/'+$route.params.id)">Edit</button>
                        <div class="badge badge-md badge-primary" v-if="model.is_active == true">• Active</div>
                        <div class="badge badge-md badge-danger" v-else>• Not Active</div>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-4" v-for="product in model.products" :key="product.id">
                        <div class="card mb-1" style="border: 1px solid #DFE1EF;">
                            <div class="card-body p-1">
                                <div class="row pl-1 pr-1">
                                    <img :src="product.product.photos[0]" alt="" class="rounded img-fluid" style="width: 62px;">
                                    <div class="col">
                                        <p class="f-14 font-500 mb-0">{{product.product.name}}</p>
                                        <p class="f-14 font-500 mb-0 strikethrough">{{product.product.price | currency}}</p>
                                        <p class="f-14 font-500 mb-0">{{product.product.selling_price | currency}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="" class="text-secondary">Periode</label>
                    <p class="f-16 font-500 mb-0">{{ model.start_date | moment('LL') }} - {{ model.end_date | moment('LL') }}</p>
                </div>
                <div class="form-group">
                    <label for="" class="text-secondary">Jumlah Potongan</label>
                    <p class="f-16 font-500 mb-0 text-success" v-if="model.discount_type == 'persen'">{{ model.discount_amount | numFormat }} %</p>
                    <p class="f-16 font-500 mb-0 text-success" v-if="model.discount_type == 'rupiah'">{{ model.discount_amount | currency }}</p>
                </div>
                <hr>
                <div class="row pl-1 mb-1">
                    <i class="material-icons f-25 mr-1">history</i>
                    <p class="f-14 font-500 mb-0 align-self-center mr-1">Histori Pemakaian</p> | 
                    <p class="f-14 font-500 mb-0 align-self-center ml-1">Total: <span class="text-success">99</span></p>
                </div>
                <Table 
                    :dataTable="table.dataTable"
                    :title="table.title">

                    <template v-slot:thead>
                        <thead>
                            <tr>
                                <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:action="{ data }">
                        <div class="btn-action-block">
                            <router-link :to="'/transactions/detail/' + data.id">
                                <button type="button" class="btn btn-rounded btn-primary waves-effect waves-light" title="Lihat Transaksi">
                                    Lihat Transaksi
                                </button>
                            </router-link>
                        </div>
                    </template> 
                </Table>
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue'
export default {
    components: {
        Table
	},
    data() {
        return {
            model: {},
            table: {
                dataTable: {
                    header: ['No', 'Nama Pembeli', 'Waktu Penggunaan', 'Action'],
                    columns: ['counter', 'buyer_name', 'created_at'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false
        }
    },
    methods: {
        show() {
            let _ = this
            _.axios.get('/promo/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        history() {
            //
        }
    },
    mounted() {
        let _ = this
        _.show()
        _.history()
        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>