<template>
    <div class="card">
        <div class="card-content">
            <div class="card-body">
                <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Produk</h3>                        
                <ValidationObserver class="card-content" ref="observer">
                    <form @submit.prevent="store()">
                        <div class="row">
                            <div class="col-lg-6">
                                <ValidationProvider name="Foto" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Foto</label>
                                        <div class="row pl-1 pr-1">
                                            <div class="" v-if="model.photos.length > 0">
                                                <div v-for="(image, index) in model.photos" :key="index" 
                                                    class="text-center profile-avatar">
                                                    <img :src="image" class="img-fluid rounded mt-1 mr-1 pointer" 
                                                        alt="" style="width: 107px; height: 108px;"><br>
                                                    <div class="edit-avatar">
                                                        <button 
                                                            @click="deleteFile(image)"
                                                            class="btn btn-rounded btn-danger" 
                                                            title="Delete"
                                                            type="button">
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <label for="file-upload" class="pointer">
                                                <img src="/assets/img/upload_image.png" alt="" class="img-fluid mt-1">
                                            </label>
                                            <input id="file-upload" class="input-file-avatar d-none" type="file" @change="upload($event)">
                                        </div>
                                        
                                        <input type="hidden" v-model="model.photos">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Kategori" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Kategori</label>
                                        <select name="" id="" v-model="model.id_category" class="form-control">
                                            <option :value="null">Pilih Kategori</option>
                                            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                                        </select>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Nama</label>
                                        <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan Nama">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Deskripsi</label>
                                        <textarea name="" id="" cols="30" rows="5" class="form-control" v-model="model.description" placeholder="Masukkan Deskripsi"></textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Harga Dasar" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Harga Dasar</label>
                                        <vue-numeric v-model="model.price" class="form-control" placeholder="Masukkan Harga Dasar" currency="Rp" separator="."></vue-numeric>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-lg-6">
                                <label for="">Harga Grosir</label>
                                <hr>
                                <div class="" v-for="(price, index) in model.prices" :key="index">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group mb-0">
                                                <label for="">Berat Minimal</label>
                                                <vue-numeric class="form-control" placeholder="Masukkan Berat Minimal" currency="Kg" separator="."
                                                    v-model="price.kg_min" currency-symbol-position="suffix">
                                                </vue-numeric>
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <div class="form-group mb-0">
                                                <label>Harga</label>
                                                <vue-numeric v-model="price.kg_price" class="form-control" placeholder="Masukkan Harga" currency="Rp" separator="."></vue-numeric>
                                            </div>
                                        </div>
                                        <button class="btn btn-danger btn-sm align-self-end mr-1" style="height: 38px;" 
                                            title="Hapus Harga Grosir" type="button" @click="remove_harga_grosir(index)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                    <hr>
                                </div>
                                <button class="btn btn-primary btn-sm" type="button" 
                                    @click="add_harga_grosir()">
                                    <i class="fa fa-plus"></i> Tambah Harga Grosir
                                </button>
                            </div>
                        </div>
                        <div class="text-right">
                            <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                            <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import ld from 'lodash'
export default {
    data() {
        return {
            model: {
                id_category: null,
                name: null,
                description: null,
                photos: [],
                price: 0,
                prices: []
            },
            categories: [],
            isLoading: false
        }
    },
    methods: {
        get_category() {
            let _ = this
            _.axios.get('/category')
                .then(resp => {
                    _.categories = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        show() {
            let _ = this
            _.axios.get('/product/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async store() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.$route.params.id) {
                    _.update()
                    return
                }

                let data = {
                    ..._.model,
                    photos: JSON.stringify(_.model.photos),
                    prices: JSON.stringify(_.model.prices)
                }
                _.axios.post('/product', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message,
                        })
                        _.isLoading = false
                        _.$router.push('/products')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.errors
                        })
                        _.isLoading = false
                    })
            }
        },
        update() {
            let _ = this
            let data = {
                ..._.model,
                photos: JSON.stringify(_.model.photos),
                prices: JSON.stringify(_.model.prices)
            }
            _.axios.put('/product/' + _.$route.params.id, data)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.$router.push('/products')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })  
        },
        upload(event){
            let _ = this
            let file = event.target.files[0]
            let formData = new FormData()
            formData.append('file', file)

            _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    _.model.photos.push(resp.data.data.url)
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        deleteFile (url){
            let _ = this
            _.model.photos = ld.filter(_.model.photos, function(o){
                return o != url;
            })
        },
        add_harga_grosir() {
            let _ = this
            _.model.prices.push({
                kg_min: 0,
                kg_price: 0
            })
        },
        remove_harga_grosir(index) {
            let _ = this
            _.model.prices.splice(index, 1)
        }
    },
    mounted() {
        let _ = this

        _.get_category()
        if (_.$route.params.id){
            _.show()
        }
    }
}
</script>