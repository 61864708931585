<template>
    <div class="card">
        <div class="card-body">
            <h3 class="font-600 mb-1">Manajemen User</h3>
            <ul class="nav nav-tabs mt-1" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="pembeli-tab" data-toggle="tab" href="#pembeli" role="tab" aria-controls="pembeli" aria-selected="true"
                        @click="activeState = 'customer'">
                        Manajemen Pembeli
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="admin-tab" data-toggle="tab" href="#admin" role="tab" aria-controls="admin" aria-selected="false"
                        @click="activeState = 'admin'">
                        Manajemen Admin
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="pembeli" role="tabpanel" aria-labelledby="pembeli-tab">
                    <Customer v-if="activeState == 'customer'"/>
                </div>
                <div class="tab-pane fade show" id="admin" role="tabpanel" aria-labelledby="admin-tab">
                    <Admin v-if="activeState == 'admin'"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Customer from '@/views/user/customer/Index.vue'
import Admin from '@/views/user/admin/Index.vue'

export default {
    components: {
        Customer,
        Admin
    },
    data() {
        return {
            activeState: 'customer'
        }
    },
}
</script>